"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var vue_router_1 = require("vue-router");
var NavBar_vue_1 = require("@/components/NavBar.vue");
var FooterSection_vue_1 = require("@/components/FooterSection.vue");
exports.default = (0, vue_1.defineComponent)({
    __name: 'App',
    setup: function (__props) {
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", null, [
                (0, vue_2.createVNode)(vue_2.Transition, {
                    name: "fade",
                    appear: ""
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(NavBar_vue_1.default)
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(vue_2.Transition, {
                    name: "slide-fade",
                    appear: ""
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)((0, vue_2.unref)(vue_router_1.RouterView))
                    ]; }),
                    _: 1
                }),
                (0, vue_2.createVNode)(vue_2.Transition, {
                    name: "fade",
                    appear: ""
                }, {
                    default: (0, vue_2.withCtx)(function () { return [
                        (0, vue_2.createVNode)(FooterSection_vue_1.default)
                    ]; }),
                    _: 1
                })
            ]));
        };
    }
});
