"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "project-content" };
var _hoisted_2 = { class: "project-title" };
var _hoisted_3 = { class: "project-social-icons" };
var _hoisted_4 = ["href"];
var _hoisted_5 = ["href"];
var _hoisted_6 = ["src", "alt"];
exports.default = (0, vue_1.defineComponent)({
    __name: 'ProjectComponent',
    props: {
        title: {},
        description: {},
        technologies: {},
        imageSrc: {},
        imageAlt: {},
        reverse: { type: Boolean },
        githubLink: {},
        devpostLink: {},
        gradientStart: { default: 'rgba(255, 255, 255, 0.1)' },
        gradientEnd: { default: 'rgba(255, 255, 255, 0.1)' }
    },
    setup: function (__props) {
        var props = __props;
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                class: (0, vue_2.normalizeClass)(["project-row", { reverse: _ctx.reverse }])
            }, [
                (0, vue_2.createElementVNode)("div", _hoisted_1, [
                    (0, vue_2.createElementVNode)("h3", _hoisted_2, (0, vue_2.toDisplayString)(_ctx.title), 1),
                    (0, vue_2.createElementVNode)("div", {
                        class: "project-description",
                        style: (0, vue_2.normalizeStyle)({
                            background: "linear-gradient(to right, ".concat(_ctx.gradientStart, ", ").concat(_ctx.gradientEnd, ")")
                        })
                    }, [
                        ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.description, function (paragraph, index) {
                            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("p", { key: index }, (0, vue_2.toDisplayString)(paragraph), 1));
                        }), 128))
                    ], 4),
                    (0, vue_2.createElementVNode)("div", {
                        class: (0, vue_2.normalizeClass)(["project-tech-container", { reverse: _ctx.reverse }])
                    }, [
                        (0, vue_2.createElementVNode)("div", {
                            class: (0, vue_2.normalizeClass)(["project-tech", { reverse: _ctx.reverse }])
                        }, [
                            ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.technologies, function (tech) {
                                return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("span", {
                                    key: tech.name
                                }, (0, vue_2.toDisplayString)(tech.name), 1));
                            }), 128))
                        ], 2),
                        (0, vue_2.createElementVNode)("div", _hoisted_3, [
                            (_ctx.githubLink)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                                    key: 0,
                                    href: _ctx.githubLink,
                                    target: "_blank",
                                    class: "social-icon"
                                }, _cache[0] || (_cache[0] = [
                                    (0, vue_2.createElementVNode)("img", {
                                        src: "/assets/github.svg",
                                        alt: "GitHub"
                                    }, null, -1)
                                ]), 8, _hoisted_4))
                                : (0, vue_2.createCommentVNode)("", true),
                            (_ctx.devpostLink)
                                ? ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("a", {
                                    key: 1,
                                    href: _ctx.devpostLink,
                                    target: "_blank",
                                    class: "social-icon"
                                }, _cache[1] || (_cache[1] = [
                                    (0, vue_2.createElementVNode)("img", {
                                        src: "/assets/devpost.svg",
                                        alt: "DevPost"
                                    }, null, -1)
                                ]), 8, _hoisted_5))
                                : (0, vue_2.createCommentVNode)("", true)
                        ])
                    ], 2)
                ]),
                (0, vue_2.createElementVNode)("div", {
                    class: (0, vue_2.normalizeClass)(["project-image", { reverse: _ctx.reverse }])
                }, [
                    (0, vue_2.createElementVNode)("img", {
                        src: _ctx.imageSrc,
                        alt: _ctx.imageAlt || _ctx.title
                    }, null, 8, _hoisted_6),
                    (0, vue_2.createElementVNode)("div", {
                        class: (0, vue_2.normalizeClass)(["image-overlay", { reverse: _ctx.reverse }]),
                        style: (0, vue_2.normalizeStyle)({
                            background: "linear-gradient(to right, ".concat(_ctx.gradientStart, ", ").concat(_ctx.gradientEnd, ")")
                        })
                    }, null, 6)
                ], 2)
            ], 2));
        };
    }
});
