"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "work-container" };
var _hoisted_2 = { class: "job-header" };
var _hoisted_3 = { class: "logo-container" };
var _hoisted_4 = { class: "logo-background" };
var _hoisted_5 = ["src", "alt"];
var _hoisted_6 = { class: "job-info" };
var _hoisted_7 = { class: "job-title" };
var _hoisted_8 = { class: "job-company" };
var _hoisted_9 = { class: "job-duration" };
var _hoisted_10 = { class: "job-location" };
var _hoisted_11 = { class: "bullet-points" };
var _hoisted_12 = { class: "bullet-content" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'JobCard',
    props: {
        logo: {},
        company: {},
        role: {},
        startDate: {},
        endDate: {},
        location: {},
        bulletPoints: {},
        websiteUrl: {}
    },
    setup: function (__props) {
        var openWork = function (website) {
            window.open(website, '_blank');
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", _hoisted_2, [
                    (0, vue_2.createElementVNode)("div", _hoisted_3, [
                        (0, vue_2.createElementVNode)("div", _hoisted_4, [
                            (0, vue_2.createElementVNode)("img", {
                                onClick: _cache[0] || (_cache[0] = function ($event) { return (openWork(_ctx.websiteUrl)); }),
                                src: _ctx.logo,
                                alt: _ctx.company,
                                class: "logo-icon",
                                style: { "cursor": "pointer" }
                            }, null, 8, _hoisted_5)
                        ]),
                        (0, vue_2.createElementVNode)("div", _hoisted_6, [
                            (0, vue_2.createElementVNode)("div", _hoisted_7, (0, vue_2.toDisplayString)(_ctx.company), 1),
                            (0, vue_2.createElementVNode)("div", _hoisted_8, (0, vue_2.toDisplayString)(_ctx.role), 1),
                            (0, vue_2.createElementVNode)("div", _hoisted_9, (0, vue_2.toDisplayString)(_ctx.startDate) + " - " + (0, vue_2.toDisplayString)(_ctx.endDate), 1),
                            (0, vue_2.createElementVNode)("div", _hoisted_10, (0, vue_2.toDisplayString)(_ctx.location), 1)
                        ])
                    ])
                ]),
                (0, vue_2.createElementVNode)("div", _hoisted_11, [
                    ((0, vue_2.openBlock)(true), (0, vue_2.createElementBlock)(vue_2.Fragment, null, (0, vue_2.renderList)(_ctx.bulletPoints, function (point, index) {
                        return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("div", {
                            class: "bullet-point",
                            key: index
                        }, [
                            (0, vue_2.createElementVNode)("div", _hoisted_12, (0, vue_2.toDisplayString)(point), 1)
                        ]));
                    }), 128))
                ])
            ]));
        };
    }
});
