"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_2 = require("vue");
var _hoisted_1 = { class: "navbar" };
exports.default = (0, vue_1.defineComponent)({
    __name: 'NavBar',
    setup: function (__props) {
        var openResume = function () {
            window.open('/Joseph_Holm_Resume.pdf', '_blank');
        };
        var sendContact = function () {
            window.location.href = 'mailto:joseph.holm27@gmail.com';
        };
        return function (_ctx, _cache) {
            return ((0, vue_2.openBlock)(), (0, vue_2.createElementBlock)("nav", _hoisted_1, [
                (0, vue_2.createElementVNode)("div", { class: "left-group" }, [
                    (0, vue_2.createElementVNode)("button", {
                        onClick: sendContact,
                        class: "icon-button",
                        title: "Contact"
                    }, _cache[0] || (_cache[0] = [
                        (0, vue_2.createElementVNode)("i", { class: "icon email-icon" }, null, -1)
                    ])),
                    (0, vue_2.createElementVNode)("button", {
                        onClick: openResume,
                        class: "icon-button",
                        title: "Resume"
                    }, _cache[1] || (_cache[1] = [
                        (0, vue_2.createElementVNode)("i", { class: "icon resume-icon" }, null, -1)
                    ]))
                ]),
                _cache[2] || (_cache[2] = (0, vue_2.createStaticVNode)("<div class=\"right-group\" data-v-35e65ce8><a href=\"https://github.com/Hololm\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"icon-button\" data-v-35e65ce8><i class=\"icon github-icon\" data-v-35e65ce8></i></a><a href=\"https://linkedin.com/in/joseph-holm\" target=\"_blank\" rel=\"noopener noreferrer\" class=\"icon-button\" data-v-35e65ce8><i class=\"icon linkedin-icon\" data-v-35e65ce8></i></a></div>", 1))
            ]));
        };
    }
});
