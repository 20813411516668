"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_router_1 = require("vue-router");
var HomeView_vue_1 = require("../views/HomeView.vue");
var router = (0, vue_router_1.createRouter)({
    history: (0, vue_router_1.createWebHistory)('/'),
    routes: [
        {
            path: '/',
            name: 'home',
            component: HomeView_vue_1.default,
            meta: {
                title: 'Joseph Holm'
            }
        },
        {
            path: '/blog',
            name: 'blog',
            // route level code-splitting
            // this generates a separate chunk (About.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: function () { return Promise.resolve().then(function () { return require('../views/BlogView.vue'); }); },
        },
    ],
});
exports.default = router;
