"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var vue_1 = require("vue");
var vue_router_1 = require("vue-router");
exports.default = (0, vue_1.defineComponent)({
    __name: 'BlogView',
    setup: function (__props) {
        var router = (0, vue_router_1.useRouter)();
        var goToHome = function () {
            router.push('/');
        };
        return function (_ctx, _cache) {
            return null;
        };
    }
});
